<template>
    <div class="verify-code">
        <input type="text" v-model="inputCode">
        <router-link @click="verifyCode" tag="button" to="/resetpassword">NEXT</router-link>
    </div>
</template>


<script>
import gql from "graphql-tag"
export default {
    data(){
        return{
            inputCode:''
        }
    },
    methods:{
        verifyCode(){
            this.$apollo.mutate({
                mutation:gql`
                mutation verifyCode($kind: String! $code: String!){
                    verifyCode(kind:$kind, code:$code){
                        errors{
                            message
                        }
                        message
                    }
                }
                
                `,
                variables:{
                    kind: 'reset_password',
                    code: this.inputCode
                }
            })
        }
    }
}
</script>